import PropTypes from 'prop-types'
import React from 'react'
import { CCallout } from '@coreui/react'

const DocsCallout = (props) => {
  const { content } = props
  return (
    <CCallout color="info" className="bg-white m-0">
      {content ? content : ''}
    </CCallout>
  )
}

DocsCallout.propTypes = {
  content: PropTypes.object,
}

export default React.memo(DocsCallout)
