import React from 'react'
import PropTypes from 'prop-types'
import { CContainer } from '@coreui/react'
import DocsCallout from './DocsCallout'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null }
    }
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }
    render() {
        const children = this.props.children
        if (this.state.errorInfo) {
            // Error path
            return (
                <CContainer fluid className='m-4'>
                    <DocsCallout content={
                        <>
                            <h2>Error: </h2>
                            <details style={{ whiteSpace: 'pre-wrap' }}>
                                {this.state.error && this.state.error.toString()}
                                <br />
                                {this.state.errorInfo.componentStack}
                            </details>
                        </>
                    } />
                </CContainer>
            );
        }
        // Normally, just render children
        return children
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.element.isRequired
}

export default ErrorBoundary