import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL
export const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
})

class AuthService {
  async login(email, password) {
    let response = await client.post(`${API_URL}auth/login`, { email, password })
    if (response.status === 200) {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data))
      }
      return response.data
    } else {
      return response.data.message
    }
  }

  logout() {
    localStorage.removeItem('user')
  }

  async register(apellido, email, password, roles, activo) {
    let response = await client.post(API_URL + 'auth/signup', {
      apellido,
      email,
      password,
      roles,
      activo
    }, { headers: authHeader() })
    if (response.status === 200) {
      return response.data
    } else {
      return response.data.message
    }
  }

  async actualizar(num_linea, valor) {
    let response = await client.post(API_URL + 'auth/signupdate', { num_linea, valor }, { headers: authHeader() })
    if (response.status === 200) {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data))
      }
      return response.data
    } else {
      return response.data.message
    }
  }

  async ObtenerLista(id) {
    let response = await client.get(API_URL + 'auth/lista/' + id, { headers: authHeader() })
    if (response.status === 200) {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data))
      }
      return response.data
    } else {
      return response.data.message
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'))
  }
}

export default new AuthService()
