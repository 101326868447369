import React, { Suspense } from 'react'
import { Routes, Route, Navigate } from "react-router-dom"
import { CSpinner } from '@coreui/react'
import AuthService from 'src/services/auth.service'
import './scss/style.scss'
import ErrorBoundary from './components/ErrorBoundary'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Registrar = React.lazy(() => import('./views/pages/register/Register'))

const Logout = () => {
  AuthService.logout()
  return (<Navigate to='/login' />)
}

function App() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          <Route path='*' element={<DefaultLayout />} />
          <Route path='/login' element={<Login />} />
          <Route path='/registrar' element={<Registrar />} />
          <Route path='/logout' element={<Logout />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  )
}

export default App
